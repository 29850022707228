export enum PaymentPlatformTypes {
  Undefined = -1,
  System = 1,
  Manual = 2,
  Cash = 10,
  Check = 20,
  Wire = 100,
  Help2Pay = 200,
  Poli = 210,
  Ocex = 220,
  PayPal = 230,

  ChipPay = 250,
  ExLink = 260,
  GPay = 270,
  BipiPay = 280,
  Buy365 = 290,
  UnionePayX = 300,
  Long77Pay = 310,
  Long77PayUsdt = 311,
  BigPay = 320,
  UEnjoy = 330,
  UsePay = 340,
  FivePayF2F = 350,
  FivePayVA = 360,
  PaymentAsiaRMB = 370,
  DragonPay = 371,
  Bakong = 372,
  Monetix = 390,
}
