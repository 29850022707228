import { useStore } from "@/store";
import { computed } from "vue";
import { LanguageTypes } from "@/core/types/LanguageTypes";

export enum TenantTypes {
  au = 1,
  bvi = 10000,
  // mn = 10002,
  sea = 10004,
  jp = 10005,
}

export const TenantOptions = [
  { label: "BVI", value: TenantTypes.bvi },
  { label: "SEA", value: TenantTypes.sea },
  { label: "Australia", value: TenantTypes.au },
  // {label: 'Mongolia', value: TenantTypes.mn},
];

export enum tenancies {
  au = "au",
  bvi = "bvi",
  sea = "sea",
  jp = "jp",
}

export const getTenancy = computed(() => {
  // const store = useStore();
  // const user = store.state.AuthModule.user;
  // return user?.tenancy;
  const jpSites = ["jp.thebcr.com", "portal.isec.jp", "thebcrjp.com"];
  const hostname = window.location.hostname;
  if (jpSites.includes(hostname)) {
    return tenancies.jp;
  }
  const site = process.env.VUE_APP_SITE; // Access the environment variable
  return site;
});

export enum tenantNames {
  bvi = "BCR",
  jp = "I Securities",
}

export const getTenantName = computed(() => {
  const tenancy = getTenancy.value;
  return tenantNames[tenancy];
});

export const canLiveChat = computed(() => {
  const tenancy = getTenancy.value;
  const canLiveChatList = [tenancies.bvi];
  return canLiveChatList.includes(tenancy);
});

export const getTenantFavicon = computed(() => {
  const tenancy = getTenancy.value;
  const tenantFavicon = {
    [tenancies.bvi]: "/favicon.ico",
    [tenancies.jp]: "/isec-favicon.ico",
  };
  return tenantFavicon[tenancy];
});

export const setFavicon = () => {
  let link: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.head.appendChild(link);
  }
  link.href = getTenantFavicon.value;
};

export const getTenantLogo = computed(() => {
  const tenancy = getTenancy.value;
  const tenantLogo = {
    [tenancies.bvi]: {
      src: "/images/logos/logo.svg",
      style: {
        width: "140px",
      },
    },
    [tenancies.jp]: {
      src: "/images/logos/isec-logo.png",
      style: {
        width: "150px",
      },
    },
  };
  return tenantLogo[tenancy];
});

export const getTenantWalletLogo = computed(() => {
  const tenancy = getTenancy.value;
  const tenantWalletLogo = {
    [tenancies.bvi]: {
      src: "/images/logo-wallet-bg.png",
      style: {},
    },
    [tenancies.jp]: {
      src: "/images/logos/isec-logo.png",
      style: {
        width: "100px",
        height: "auto",
      },
    },
  };
  return tenantWalletLogo[tenancy];
});

export const getTenantLanguagesOptions = computed(() => {
  const tenancy = getTenancy.value;
  const tenantLanguagesOptions = {
    [tenancies.bvi]: LanguageTypes.all,
    [tenancies.jp]: [
      LanguageTypes.enUS,
      LanguageTypes.jpJP,
      LanguageTypes.zhCN,
      LanguageTypes.zhTW,
    ],
  };
  return tenantLanguagesOptions[tenancy];
});
