import {
  axiosInstance as axios,
  paramsSerializerForDotnet,
} from "@/core/services/api.client";
const prefix = "api/v1/client/";

export type PaymentServiceResType = {
  deposit: any[];
  withdrawal: any[];
};

export type WalletsResType = {
  id: number;
  type: number;
  balance: number;
  sequence: number;
  currencyId: number;
  talliedOn: string;
};

export type TradeAccountResType = {
  uid: number;
  serviceId: number;
  accountNumber: number;
  currencyId: number;
  lastSyncedOn: string;
  leverage: number;
  balance: number;
  balanceInCents: number;
};

export type DepositReqType = {
  amount: number;
  currencyId: number;
  paymentServiceId: number;
  fundType: number;
  targetTradeAccountUid: number | undefined;
  note: string | undefined;
  request: object | undefined;
  paymentServiceCategoryName: string | undefined;
};

export type WithdrawalReqType = {
  amount: number;
  walletId: number | undefined;
  paymentServiceId: number;
  request: object | undefined;
};

export type TransferReqType = {
  walletId: number;
  tradeAccountUid: number;
  amount: number;
};

export default {
  getUploadReceiptInfo: async (paymentHashId: string) =>
    (await axios.get(prefix + "payment/" + paymentHashId + "/guide")).data,

  getUnionPaymentService: async (criteria: any) =>
    (
      await axios.get(prefix + "payment-service/union-pay/service-id", {
        params: criteria,
      })
    ).data,

  getUnionPayDeposit: async (Uid: number) =>
    (
      await axios.get(
        prefix + "payment-service/union-pay/policy?accountUid=" + Uid
      )
    ).data,

  getPaymentServiceById: async (id: number) =>
    (await axios.get(prefix + "payment-service/" + id)).data,

  checkIsInitialDeposit: async (uid: number) =>
    (await axios.get(prefix + "payment-service/deposit/initial/" + uid)).data,

  queryDeposit: async (criteria?: any) =>
    (
      await axios.get(prefix + "deposit", {
        params: criteria,
        paramsSerializer: paramsSerializerForDotnet,
      })
    ).data,

  getDeposit: async (id: number) =>
    (await axios.get(prefix + "deposit/" + id)).data,

  postDeposit: async (formData: DepositReqType) =>
    (await axios.post(prefix + "deposit", formData)).data,

  putDeposit: async (id: number, data: any) =>
    (await axios.put(prefix + "deposit/" + id, data)).data,

  cancelDeposit: async (id: number) =>
    (await axios.put(prefix + "deposit/" + id + "/cancel")).data,

  queryWithdrawal: async (criteria?: any) =>
    (await axios.get(prefix + "withdrawal", { params: criteria })).data,

  postWithdrawal: async (data: any) =>
    (await axios.post(prefix + "withdrawal", data)).data,

  putWithdrawal: async (id: number, data: any) =>
    (await axios.put(prefix + "withdrawal/" + id, data)).data,

  cancelWithdrawal: async (id: number) =>
    (await axios.put(prefix + "withdrawal/" + id + "/cancel")).data,

  queryTransfer: async (criteria?: any) =>
    (await axios.get(prefix + "transaction", { params: criteria })).data,

  postTransferToWallet: async (data: any) =>
    (await axios.post(prefix + "transaction/to/wallet", data)).data,

  postTransferToTradeAccount: async (data: any) =>
    (await axios.post(prefix + "transaction/to/trade-account", data)).data,

  getPaymentServices: async (
    fundType?: number,
    currencyId?: number
  ): Promise<PaymentServiceResType> =>
    (
      await axios.get(prefix + "payment-service", {
        params: { fundType, currencyId },
      })
    ).data,

  getDepositCategory: async (fundType?: number, currencyId?: number) =>
    (
      await axios.get(prefix + "payment-service/deposit/category", {
        params: { fundType, currencyId },
      })
    ).data,

  getDepositPaymentServiceByCategory: async (
    fundType?: number,
    currencyId?: number,
    amount?: number,
    categoryName?: string
  ) =>
    (
      await axios.get(
        prefix + "payment-service/deposit/category/" + categoryName,
        {
          params: { fundType, currencyId, amount },
        }
      )
    ).data,

  getWallets: async () => (await axios.get(prefix + "wallet")).data,

  getTradeAccounts: async (criteria?: any) =>
    (
      await axios.get(prefix + "trade-account", {
        params: criteria,
        paramsSerializer: paramsSerializerForDotnet,
      })
    ).data,

  queryClientTransactionView: async (criteria?: any) =>
    (
      await axios.get(prefix + "transaction/query", {
        params: criteria,
      })
    ).data,

  queryWalletWithdrawView: async (walletId: number, criteria?: any) =>
    (
      await axios.get(prefix + `wallet/${walletId}/withdrawal`, {
        params: criteria,
      })
    ).data,

  queryWalletTransferView: async (walletId: number, criteria?: any) =>
    (
      await axios.get(prefix + `wallet/${walletId}/transfer`, {
        params: criteria,
      })
    ).data,

  queryWalletTransactionView: async (walletId: number, criteria?: any) =>
    (
      await axios.get(prefix + `wallet/${walletId}/transaction`, {
        params: criteria,
      })
    ).data,

  getDepositReceiptFile: async (id: number) =>
    (await axios.get(prefix + "deposit/" + id + "/receipt")).data,

  postDepositReceiptFile: async (id: number, file: any) =>
    (await axios.post(prefix + "deposit/" + id + "/receipt", file)).data,

  getServicePolicy: async (id: number, lang?: any) =>
    (
      await axios.get(prefix + "payment-service/" + id + "/policy", {
        params: lang,
      })
    ).data,

  getServiceInstruction: async (id: number, lang?: any) =>
    (
      await axios.get(prefix + "payment-service/" + id + "/instruction", {
        params: lang,
      })
    ).data,

  getOrderReference: async (id: number) =>
    (await axios.get(prefix + "deposit/" + id + "/reference")).data,

  getExchangeRate: async (criteria?: any) =>
    (await axios.get(prefix + "exchange-rate", { params: criteria })).data,

  getDepositExchangeRate: async (from: number, to: number) =>
    (await axios.get(prefix + "deposit/" + from + "/to/" + to)).data,
};
